// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/_index.jsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/_index.jsx");
  import.meta.hot.lastModified = "1730974561957.8047";
}
// REMIX HMR END

import { MailOutlined } from '@ant-design/icons';
import { Button, Form, Input, message } from 'antd';
import { useNavigate, useSearchParams, useLoaderData } from '@remix-run/react';
import ApiService from '../services/ApiService';
import { useEffect, useState } from 'react';
import { getSession } from '../session';
export const meta = () => {
  return [{
    title: 'D-rive Admin Dashboard'
  }, {
    name: 'description',
    content: 'Welcome to D-rive Admin Dashboard!'
  }];
};
export const loader = async ({
  params,
  request
}) => {
  const session = await getSession(request.headers.get('Cookie'));
  const companyId = session.get('companyId');
  if (companyId) {
    return {
      loggedInCompanyId: companyId
    };
  }
  return {
    data: null
  };
};
export default function LandingPage() {
  _s();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const companyId = searchParams.get('companyId');
  const {
    loggedInCompanyId
  } = useLoaderData();
  const onFinish = async values => {
    if (!companyId) {
      message.error('Invalid company credentials');
      return;
    }
    try {
      setLoading(true);
      const response = await ApiService.post(`/api/operator/auth`, {
        ...values,
        companyId
      });
      if (response.data) {
        if (companyId === '9d568e90-3df7-4c1f-b1e8-daa825b4bb6a' || companyId === 'ceb54e35-a12d-43ee-85f8-3d2447d92c93') {
          return navigate('/customers');
        } else {
          navigate('/campaign/select?type=RECEIPT');
        }
      }
      if (response.message) {
        message.error(response.message);
      }
    } catch (error) {
      console.log('error', error.response);
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (loggedInCompanyId) {
      return navigate('/campaign/select?type=RECEIPT');
    }
  }, [loggedInCompanyId, navigate]);
  return <div>
      <div className="pt-3 pb-4">
        <h2 className="text-center">TDG CRM Operation Portal</h2>
      </div>

      <div className="d-flex justify-content-center">
        <div className="card col-md-5 col-sm-10  p-5 mx-3">
          <div style={{
          marginBottom: '1.5rem'
        }} className="form-header">
            <h2 className="text-center">Operator Login</h2>
            <p className="mt-3">
              Please enter your user name and password to login
            </p>
          </div>
          <Form name="normal_login" className="login-form" layout="vertical" onFinish={onFinish} form={form}>
            <Form.Item name="email" label="Email" rules={[{
            required: true,
            message: 'Please input your Email!'
          }]}>
              <Input prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Email" />
            </Form.Item>
            <Form.Item name="password" label="Password" rules={[{
            required: true,
            message: 'Please input your Password!'
          }]}>
              <Input.Password />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" className="login-form-button" block loading={loading}>
                Login
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>;
}
_s(LandingPage, "wOVgs8pH1bZ0tvXvkhY4kR9bqzE=", false, function () {
  return [Form.useForm, useNavigate, useSearchParams, useLoaderData];
});
_c = LandingPage;
var _c;
$RefreshReg$(_c, "LandingPage");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;